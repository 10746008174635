<template lang="pug">
.lots-list-options(v-if="options?.info?.unseenCountLots")
  .lots-list-options__card.row
    .block
      .label {{ foundTitle }}
      .content {{ options.info.count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}
    .block
      .label {{ sumTitle }}
      .content ~ {{ compactCostFormatter("RUB").format(options.info.sumCost) }}
  .lots-list-options__card.row
    .block
      .label Непросмотренных тендеров
      .content {{ options.info.unseenCountLots?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}
    .block
      .label На сумму
      .content ~ {{ compactCostFormatter("RUB").format(options.info.unseenSumCost) }}

.lots-list-options(v-else)
  .lots-list-options__card(:class="!options?.info.selective && '_selective'")
    .label {{ foundTitle }}
    ui-loader.loader(v-if="options?.loading" mini)
    .content(v-else) {{ options.info.count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }}
  .lots-list-options__card(v-if="options?.info.selective")
    .label {{ sumTitle }}
    ui-loader.loader(v-if="options?.loading" mini)
    .content(v-else) ~ {{ compactCostFormatter("RUB").format(options.info.sumCost) }}
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { compactCostFormatter } from "@/utils/formatter/costFormatter";

import type { PropType } from "vue";
import type { MainStreamListInfoOptions } from "@/utils/getters/defaultRequestInfo";
import UiLoader from "@/components/ui/loader/UiLoader.vue";
import UiWarning from "~/components/ui/tooltip/UiWarning.vue";

export default defineComponent({
  name: "ListOptions",
  components: {
    UiWarning,
    UiLoader,
  },
  methods: {
    compactCostFormatter,
  },
  props: {
    options: {
      type: Object as PropType<MainStreamListInfoOptions>,
      default: () => ({}),
    },
    foundTitle: {
      type: String,
      default: 'Найдено тендеров',
    },
    sumTitle: {
      type: String,
      default: 'На сумму',
    },
  },
});
</script>

<style scoped lang="scss">
.lots-list-options {
  display: flex;
  width: var(--main-content-width);
  justify-content: space-between;
  margin-bottom: 40px;

  .loader {
    width: 30px;
  }
}

.lots-list-options__card {
  display: flex;
  flex-flow: column;
  gap: 8px;
  text-align: center;
  justify-content: center;
  align-items: center;

  height: 74px;
  width: calc(50% - 10px);

  border: 1px solid #D4D7DE;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 40px;
  background-color: white;

  &.row {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 16px;
  }

  &._selective {
    width: 100%;
    flex-flow: row;
    gap: 40%;
  }

  .label {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }

  .content {
    font-size: 22px;
    font-weight: 700;
  }

  .block {
    display: flex;
    flex-flow: column;
    gap: 8px;
  }
}
</style>
